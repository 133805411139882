<template>
    <b-modal
        v-model="showModal"
        header-bg-variant="primary"
        size="xl"
        :footer-class="['inventory-lookup-footer', 'd-flex', {'bg-light': showPrompt}]"
        header-class="inventory-lookup-header flex-wrap flex-column flex-md-row  pt-3 py-0"
        dialog-class="modal-dialog-centered"
        body-class="p-0"
        no-close-on-backdrop
        no-close-on-esc
    >
        <template #modal-header>
            <div class="align-middle text-center text-md-left pb-2">
                <label
                    class="d-block d-md-inline text-big heavy text-white pl-0 side-text align-middle"
                    >{{ side }}</label
                >
                <b-img
                    :src="icon"
                    class="d-block d-md-inline m-auto mx-md-3 pb-2 pt-3 py-md-0"
                ></b-img>
                <label class="d-block d-md-inline text-title heavy text-white align-middle">
                    {{ t('invLookup_title') }}
                </label>
            </div>
            <div class="text-white text-title pb-2">
                <b-tabs v-model="tabIndex" pills @activate-tab="isManufactureViewEnabled = false">
                    <b-tab
                        :title="t('invLookup_Consignment')"
                        :title-item-class="{'d-none': !shouldShowConsignmentInventory}"
                        title-link-class="text-white"
                    ></b-tab>
                    <b-tab
                        :title="`${t('staarName')} ${currentBusinessUnit}`"
                        :title-item-class="{'d-none': !shouldShowLocalInventory}"
                        title-link-class="text-white"
                    ></b-tab>
                    <b-tab
                        :title="`${t('staarName')} ${remoteBusinessUnit}`"
                        :title-item-class="{'d-none': !shouldShowStaarInventory}"
                        title-link-class="text-white"
                    ></b-tab>
                </b-tabs>
            </div>
            <b-form-group
                class="mb-0 pb-2 w-100pm360"
                :label="t('targetLens')"
                label-class="text-white"
                label-for="description"
                label-cols="12"
                label-cols-sm
            >
                <b-form-input
                    v-model="targetLensDescription"
                    class="text-body font-weight-bolder w-360px"
                    disabled
                ></b-form-input>
            </b-form-group>
        </template>

        <template #default>
            <b-table
                sticky-header="538px"
                :items="inventoryData"
                :fields="fields"
                class="inventory-table"
                :emptyText="t('invLookup_NoLenses')"
                show-empty
            >
                <template #empty="scope">
                    <b-overlay :show="true" no-wrap>
                        <template #overlay>
                            <div class="text-center p-3">
                                <h3>
                                    <strong>{{ scope.emptyText }}</strong>
                                </h3>
                                <template v-for="reason in noLensReasonsKeys">
                                    <h4 :key="reason" v-if="inventoryResults.noLensReasons[reason]">
                                        <strong
                                            ><div v-html="t(`invLookup_${reason}`)"></div
                                        ></strong>
                                    </h4>
                                </template>
                            </div>
                        </template>
                    </b-overlay>
                </template>
                <template #table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{width: colWidths[field.key]}"
                    />
                </template>

                <template #cell(version)="{item}">
                    <span class="text-nowrap" :style="{color: item.color}">{{ item.version }}</span>
                </template>

                <template #cell(quantity)="{item}">
                    <div
                        :class="`min-w-82px text-center
                            ${
                                !isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED]) &&
                                remainingSelectableLenses === 0
                                    ? 'text-muted'
                                    : ''
                            }`"
                        v-if="
                            isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED]) ||
                            getQuantityOptions(item).length === 1
                        "
                    >
                        {{ item.quantity }}
                    </div>
                    <b-form-select
                        v-else
                        :options="getQuantityOptions(item)"
                        :value="item.quantity"
                        :disabled="isMaxSelectableReached"
                        @change="setQuantity(item, $event)"
                        size="sm"
                        class="min-w-82px p-0 text-center centered-select"
                    >
                        <template #first>
                            <b-form-select-option :value="0">{{
                                t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </template>

                <template #cell(status)="{item}">
                    <div v-if="!isConsignmentTabActive && item.isPrimaryLens" class="ml-2">
                        <PrimaryMarker />
                    </div>
                </template>

                <template #cell(buttons)="{item}">
                    <div class="d-inline-flex">
                        <b-button
                            v-if="
                                !isManufactureViewEnabled && !isConsignmentTabActive && canAddToCart
                            "
                            size="sm"
                            class="my-1 mr-3 btn-block-lg-down text-nowrap min-w-92px"
                            :variant="
                                isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED])
                                    ? 'primary-dark'
                                    : 'primary'
                            "
                            :disabled="
                                cartButtonsDisabled(item) ||
                                isInStatus(item, [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED])
                            "
                            @click="addToCart(item)"
                        >
                            {{ cartButtonText(item) }}</b-button
                        >
                        <b-button
                            v-if="!isManufactureViewEnabled && canReserve"
                            :class="[
                                'my-1 btn-block-lg-down text-nowrap min-w-72px',
                                {'btn-primary': cartButtonsDisabled(item)},
                            ]"
                            size="sm"
                            :variant="
                                isInStatus(item, [null, LENS_STATUS.AVAILABLE, LENS_STATUS.ACTIVE])
                                    ? 'primary'
                                    : 'gray-dark'
                            "
                            :disabled="cartButtonsDisabled(item)"
                            @click="reserveOrCancel(item)"
                            >{{ reserveOrCancelButtonText(item) }}</b-button
                        >
                        <b-button
                            v-if="isManufactureViewEnabled"
                            class="my-1 btn-block-lg-down text-nowrap"
                            size="sm"
                            disabled
                            block
                            variant="secondary"
                            >{{ t('invLookup_NotInStock') }}</b-button
                        >
                    </div>
                </template>
            </b-table>

            <div v-if="isManufactureViewEnabled" class="manufacture-overlay">
                <div class="content">
                    <div class="info">
                        <p class="mb-0">
                            {{ t('invLookup_NoInStockBasedOnInput') }}
                        </p>
                        <p>{{ t('invLookup_LensMustBeMade') }}</p>
                    </div>
                    <div class="mt-2">
                        <label class="w-100">{{ t('invLookup_Quantity') }}</label>
                        <b-form-select
                            :options="manufactureQuantityOptions"
                            v-model="manufactureQuantity"
                            :disabled="manufactureLensDisabled"
                            size="sm"
                            class="w-auto"
                        >
                        </b-form-select>
                    </div>
                    <div v-if="isToric" class="info note-not-exact-match">
                        <h6>{{ t('invLookup_LensNotExactMatch') }}</h6>
                    </div>
                </div>
            </div>

            <b-overlay :show="showPrompt" no-wrap>
                <template #overlay>
                    <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3"
                    >
                        <div
                            v-html="
                                t('invLookup_CartCancel', {
                                    reservedOrAssigned: isConsignmentTabActive
                                        ? t('inventory_Assigned')
                                        : t('inventory_Reserved'),
                                })
                            "
                        ></div>

                        <div class="d-flex center">
                            <b-button
                                variant="outline-danger"
                                size="lg"
                                class="mr-3"
                                @click="showPrompt = false"
                            >
                                {{ t('no') }}
                            </b-button>
                            <b-button
                                variant="outline-success"
                                size="lg"
                                @click="onConfirmCancelation"
                            >
                                {{ t('yes') }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>
        </template>

        <template #modal-footer>
            <b-container fluid :class="{invisible: showPrompt}">
                <b-row>
                    <b-col class="py-1 align-self-center" md="5">
                        <div class="d-flex justify-content-between">
                            <div v-if="showManufactureButton" class="align-self-center">
                                <b-button
                                    class="float-right float-sm-left"
                                    :disabled="manufactureLensDisabled"
                                    variant="purple"
                                    @click="showManufactureView"
                                    >{{ t('invLookup_ManufactureLens') }}
                                    <b-icon icon="gear-fill" class="ml-2"></b-icon
                                ></b-button>
                            </div>
                            <div class="align-self-center" :class="{'ml-3': showManufactureButton}">
                                <span class="text-caption heavy text-muted mr-2">
                                    {{ t('preop_SelDiffLength') }}
                                </span>
                                <b-form-select
                                    :value="selectedLength"
                                    :options="lengthOptions"
                                    :disabled="isMaxSelectableReached"
                                    @change="selectedLengthChange"
                                    class="text-body light w-auto"
                                >
                                </b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="py-1 align-self-center" md="2">
                        <p
                            v-if="isLimitedQuantity && !isConsignmentTabActive"
                            class="text-center align-middle font-weight-bold d-inline"
                        >
                            {{ t('inventory_backOrderNote') }}
                        </p>
                    </b-col>
                    <b-col class="py-1 align-self-center" md="5">
                        <div class="float-right text-right">
                            <b-button
                                id="printReport"
                                @click="printReport"
                                class="pointer ml-auto mr-2"
                            >
                                {{ t('inventoryPrintReport') }}
                                <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                            </b-button>
                            <p
                                class="mb-0 text-center align-middle font-weight-bold mr-3 d-inline-flex w-135px"
                                :class="{'text-danger': isTimeAlmostOver}"
                            >
                                <b-icon class="mr-1" icon="clock" />
                                <span class="pr-1 w-40px">{{ timer }} </span>
                                {{ t('inventory_remaining') }}
                            </p>
                            <b-button class="my-1 mr-1" @click="onBackOrCancel">{{
                                backButtonText
                            }}</b-button>
                            <b-button
                                v-if="!isManufactureViewEnabled"
                                :disabled="!wasOperationPerfomed"
                                @click="done"
                                variant="primary"
                                >{{ t('inventory_Done') }} <b-icon icon="check"></b-icon
                            ></b-button>
                            <b-button
                                v-if="isManufactureViewEnabled"
                                :disabled="manufactureLensDisabled"
                                class="my-1"
                                variant="purple"
                                @click="manufactureLens"
                                >{{ t('invLookup_ManufactureLens') }}</b-button
                            >
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>
<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import uniqueId from 'lodash/uniqueId';
import findLastIndex from 'lodash/findLastIndex';
import {
    formatLensNum,
    padNumber,
    formatNumber,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import {BUCodes} from '@/constants/user';
import {LensModelCodes, DefaultManufactureInventory} from '@/store/modules/preopdata';
import ODIcon from '@/assets/preop-data/white-od-eyes.svg';
import OSIcon from '@/assets/preop-data/white-os-eyes.svg';
import PrimaryMarker from '@/components/PrimaryMarker';

import Worker from 'worker-loader!@/workers/interval.js';

const LENS_TAB_INDEXES = {
    CONSIGNMENT: 0,
    LOCAL: 1,
    STAAR: 2,
};

const LENS_SOURCES = {
    MANUFACTURE: null, // This is not a source, is a special case need to be handle
    STAAR: 1,
    CONSIGNMENT: 2,
    STAARLOCAL: 3,
    STAARREMOTE: 4,
};

const LENS_STATUS = {
    ACTIVE: 'Active',
    AVAILABLE: 'AVAILABLE',
    IN_CART: 'IN_CART',
    RESERVED: 'RESERVED',
};

const NO_LENS_REASONS_KEYS = [
    'sphereNotInRegCountryDiopterRange',
    'seqNotInRegCountryDiopterRange',
    'cylinderNotInRegCountryCylinderRange',
    'noRegCountryRowExists',
];

const MINUTES_TO_ALERT_TIME = 3;
const MAX_INVENTORY_ITEMS = 10;
const MAX_MANUFACTURE_LENS_IN_CART = 4;

export default {
    components: {
        PrimaryMarker,
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        preOpDataId: {
            type: Number,
            default: null,
        },
        lensInformation: {
            type: Object,
            default: () => ({}),
        },
        lengthOptions: {
            type: Array,
            default: () => [],
        },
        selectedLengthOption: {
            type: Number,
            default: () => null,
        },
        targetLensDescription: {
            type: String,
            default: null,
        },
        side: {
            type: String,
            default: null,
        },
        allLensInfo: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            ODIcon,
            OSIcon,
            LENS_STATUS,
            tabIndex: LENS_TAB_INDEXES.LOCAL,
            noLensReasonsKeys: NO_LENS_REASONS_KEYS,
            manufactureQuantity: MAX_MANUFACTURE_LENS_IN_CART,
            lensesAdded: [],
            selectedLength: null,
            isManufactureView: false,
            showPrompt: false,
            worker: new Worker(),
            minutes: 0,
            seconds: 0,
            colWidths: {
                lensId: '100px',
                model: '150px',
                version: '300px',
                sphere: '100px',
                cylinder: '100px',
                axis: '100px',
                expectedRef: '170px',
                expectedSeq: '100px',
                quantity: '100px',
                buttons: '300px',
            },
        };
    },
    watch: {
        showModal(newValue) {
            if (newValue) {
                this.worker.postMessage({
                    action: 'Start',
                    interval: 1000,
                    duration: 60 * 20,
                });
                this.selectedLength = this.selectedLengthOption;
                this.selectTab();
            } else {
                this.worker.postMessage({action: 'End'});
            }
        },
    },
    computed: {
        ...mapGetters('user', ['currentUser', 'activeCustomer', 'activeCustomerOcosNumberOrId']),
        ...mapState({
            inventoryResults(state) {
                localStorage.inventoryLookupData = JSON.stringify({
                    preOpDataSetId: state.preopdata.preopdata.preOpDataSetId,
                    inventoryResults: {[this.side]: state.preopdata.inventoryResults[this.side]},
                });

                return get(state.preopdata.inventoryResults, this.side, {});
            },
            primaryLensOD(state) {
                return state.patient.currentPatientPrimaryLensOD;
            },
            primaryLensOS(state) {
                return state.patient.currentPatientPrimaryLensOS;
            },
        }),
        isManufactureViewEnabled: {
            get: function () {
                return this.isManufactureView && this.isStaarTabActive;
            },
            set: function (newValue) {
                this.isManufactureView = newValue;
            },
        },
        canAddToCart() {
            return this.checkPermissions({
                [this.PERMISSIONS.ORDERING]: [this.PERMISSIONS_VALUES.READ_WRITE],
            });
        },

        canReserve() {
            return this.checkPermissions({
                [this.PERMISSIONS.RESERVATIONS]: [this.PERMISSIONS_VALUES.READ_WRITE],
            });
        },

        remoteBusinessUnit() {
            switch (this.activeCustomerBusinessUnit) {
                case BUCodes.JP:
                    return BUCodes.CH.replace(' BU', '');
                case BUCodes.CH:
                    return BUCodes.US.replace(' BU', '');
                default:
                    return this.currentBusinessUnit;
            }
        },

        isWithinMaxSelectableLensesForConsignmentMatches() {
            const isWithinMaxForConsignmentMatches = get(
                this.inventoryResults,
                'isWithinMaxSelectableLensesForConsignmentMatches',
                false
            );
            return isWithinMaxForConsignmentMatches;
        },

        isWithinMaxSelectableLensesForLocalMatches() {
            const isWithinMaxForLocalMatches = get(
                this.inventoryResults,
                'isWithinMaxSelectableLensesForLocalMatches',
                false
            );
            return isWithinMaxForLocalMatches;
        },

        shouldShowConsignmentInventory() {
            // JP BU should see tab always, assuming they have consignment rights (GLI #1224)
            return (
                (this.isJPBusinessUnit ||
                    get(this.inventoryResults, 'hasConsignmentInventory', false)) &&
                get(this.currentUser, 'consignmentRights', false)
            );
        },

        shouldShowLocalInventory() {
            // JP BU should see tab always (GLI #1224)
            return (
                this.isJPBusinessUnit ||
                (!this.isUSBusinessUnit && !this.isWithinMaxSelectableLensesForConsignmentMatches)
            );
        },

        shouldShowStaarInventory() {
            // - staar inventory is treat as local when is US BU
            // - JP BU should see tab always (GLI #1224)
            return (
                this.isJPBusinessUnit ||
                (this.isUSBusinessUnit
                    ? !this.isWithinMaxSelectableLensesForConsignmentMatches
                    : !this.isWithinMaxSelectableLensesForLocalMatches)
            );
        },

        manufactureInfo() {
            return (this.inventoryResults.localLenses || []).find(
                (x) => x.isMto && this.isInStatus(x, [LENS_STATUS.AVAILABLE, LENS_STATUS.ACTIVE])
            );
        },

        /**
         * Sets the primary lens status of the inventory data.
         *
         * If there is no primary lens for this eye (no lens has been previously
         * ordered or added to the cart), set the lens with the oldest add-to-cart
         * value as the primary lens.
         */
        inventoryData() {
            const localAndRemotes = [...this.localInventory, ...this.staarInventory];
            if (!this[`primaryLens${this.side.toUpperCase()}`] && localAndRemotes.length > 0) {
                let primaryLens = minBy(localAndRemotes, 'addedToCart');
                localAndRemotes.forEach((lens) => {
                    lens['isPrimaryLens'] = lens === primaryLens;
                });
            }
            switch (this.tabIndex) {
                case LENS_TAB_INDEXES.STAAR:
                    return this.staarInventory;
                case LENS_TAB_INDEXES.CONSIGNMENT:
                    return this.consignmentInventory;
                case LENS_TAB_INDEXES.LOCAL:
                    return this.localInventory;
                default:
                    return [];
            }
        },

        staarInventory() {
            if (this.isManufactureViewEnabled) {
                return [{...DefaultManufactureInventory(), ...this.manufactureInfo}];
            } else {
                const staarInventorySource = this.isUSBusinessUnit ? 'localLenses' : 'remoteLenses';
                let lensInventory = (this.inventoryResults[staarInventorySource] || []).filter(
                    (x) => !x.isMto || x.status === LENS_STATUS.IN_CART
                );
                this.lensInventoryMapper(lensInventory);
                /**When we add MTO lenses there is the possibility that the last lenses are added as reserved
                 * or added to the cart, they will be hidden if their positions are not exchanged. */
                lensInventory
                    .slice(MAX_INVENTORY_ITEMS)
                    .filter((x) => [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(x.status))
                    .forEach((lens) => {
                        const lensIndex = lensInventory.indexOf(lens);
                        const swapIndex = findLastIndex(
                            lensInventory.slice(0, MAX_INVENTORY_ITEMS),
                            (x) => this.isInStatus(x, [LENS_STATUS.AVAILABLE, LENS_STATUS.ACTIVE])
                        );
                        this.swapArrayLocs(lensInventory, lensIndex, swapIndex);
                    });
                return lensInventory.slice(0, MAX_INVENTORY_ITEMS);
            }
        },

        consignmentInventory() {
            const lensInventory = (this.inventoryResults.consignmentLenses || []).map((lens) => {
                lens.quantity = 1;
                lens.isPrimaryLens = null; // Consignment lenses don't use the primary status
                //find the lens in allLensInfo
                //and update the expected Ref & SEQ fields
                const {cyl, sphere} = lens;
                let section = this.allLensInfo?.find((v) => v.cylinder == cyl);
                if (section) {
                    //with the section, find the index that matches the sphere value we have
                    let sphereIndex = section.sphere.findIndex((v) => v == sphere);
                    //use this index to build the ExpRef and ExpSeq
                    if (sphereIndex) {
                        lens.expectedSeq = this.formatLensNum(section.expSeq[sphereIndex], 2, 2);
                    }
                }
                return lens;
            });
            return lensInventory;
        },

        localInventory() {
            let lensInventory = (this.inventoryResults.localLenses || []).filter(
                (x) => !x.isMto || x.status === LENS_STATUS.IN_CART
            );
            this.lensInventoryMapper(lensInventory);
            return lensInventory;
        },

        isToric() {
            return this.lensInformation.calculationType == LensModelCodes.TORIC;
        },

        isSpheric() {
            return this.lensInformation.calculationType == LensModelCodes.SPHERIC;
        },

        isStaarTabActive() {
            return this.tabIndex === LENS_TAB_INDEXES.STAAR;
        },

        isConsignmentTabActive() {
            return this.tabIndex === LENS_TAB_INDEXES.CONSIGNMENT;
        },

        isLocalTabActive() {
            return this.tabIndex === LENS_TAB_INDEXES.LOCAL;
        },

        isLimitedQuantity() {
            return this.staarInventory.some((lens) => lens.limitedQuantity);
        },

        icon() {
            return this[`${this.side}Icon`];
        },

        toricFields() {
            let toricFields = [
                ...[
                    // if consignment, insert the serial number as the first column
                    this.isConsignmentTabActive
                        ? {
                              key: 'inventoryItemId',
                              stickyColumn: true,
                              label: this.t('serialNo'),
                              formatter: (_, __, row) => {
                                  return row.serials[0].serialNumber;
                              },
                          }
                        : {},
                ],
                {
                    key: 'model',
                    stickyColumn: !this.isConsignmentTabActive,
                    label: this.t('invLookupCol_Model'),
                },
                {key: 'version', label: this.t('invLookupCol_Version')},
                {
                    key: 'sphere',
                    label: this.t('invLookupCol_Sphere'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'cylinder',
                    label: this.t('invLookupCol_Cylinder'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'serials',
                    label: this.t('invLookupCol_Axis'),
                    formatter: (value) =>
                        /* @NOTE: Use only the first serialAxis value in the array,
                                  under the assumption that the API will always group only
                                  serials that share an axis.
                        */
                        this.padNumber(value[0]?.serialAxis, 2, this.currentUser.decimalSeparator),
                },
                {
                    key: 'expectedRef',
                    label: this.t('invLookupCol_ExpRef'),
                    formatter: (value) =>
                        decimalSeparatorFormatter(value, this.currentUser.decimalSeparator),
                },
                {
                    key: 'expectedSeq',
                    label: this.t('invLookupCol_ExpSEQ'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
                ...[
                    !this.isConsignmentTabActive && !this.isManufactureViewEnabled
                        ? {
                              key: 'quantity',
                              label: this.t('invLookup_Quantity'),
                              class: 'text-center',
                          }
                        : {},
                ],
                ...[
                    !this.isConsignmentTabActive && this.inventoryResults.maxSelectable > 1
                        ? {key: 'status', label: this.t('invLookupCol_Status')}
                        : {},
                ],
                {key: 'buttons', label: ''},
            ];

            return toricFields;
        },

        sphericFields() {
            const sphericFields = [
                ...[
                    // if consignment, insert the serial number as the first column
                    this.isConsignmentTabActive
                        ? {
                              key: 'inventoryItemId',
                              stickyColumn: true,
                              label: this.t('serialNo'),
                              formatter: (_, __, row) => {
                                  return row.serials[0].serialNumber;
                              },
                          }
                        : {},
                ],
                {
                    key: 'model',
                    stickyColumn: !this.isConsignmentTabActive,
                    label: this.t('invLookupCol_Model'),
                },
                {key: 'version', label: this.t('invLookupCol_Version')},
                {
                    key: 'length',
                    label: this.t('preopreport_Length'),
                    formatter: (value) =>
                        `${formatNumber(value, 1, this.currentUser.decimalSeparator)} mm`,
                },
                {
                    key: 'sphere',
                    label: this.t('inventory_Diopter'),
                    formatter: (value) =>
                        this.formatLensNum(value, 2, this.currentUser.decimalSeparator),
                },
            ];
            // LOCAL and REMOTE inventory need to set the quantity dropdown
            if (!this.isConsignmentTabActive) {
                sphericFields.push({
                    key: 'quantity',
                    label: this.t('invLookup_Quantity'),
                    class: 'text-center',
                });
                if (this.inventoryResults.maxSelectable > 1) {
                    sphericFields.push({key: 'status', label: this.t('invLookupCol_Status')});
                }
            }
            sphericFields.push({key: 'buttons', label: ''});
            return sphericFields;
        },

        fields() {
            return this.isToric ? this.toricFields : this.sphericFields;
        },

        showManufactureButton() {
            return (
                !this.isManufactureViewEnabled &&
                get(this.inventoryResults, 'canMto', false) &&
                this.isStaarTabActive &&
                this.canAddToCart
            );
        },

        wasOperationPerfomed() {
            return !!this.lensesAdded.length;
        },

        remainingSelectableLenses() {
            return this.inventoryResults.maxSelectable - this.quantitySelected;
        },

        quantitySelected() {
            return this.lensesAdded
                .filter((item) => item.lensSourceId !== LENS_SOURCES.CONSIGNMENT) // consignment lenses should not count for remainingSelectableLenses or maxSelectable computations
                .reduce((acc, lens) => acc + lens.quantity, 0);
        },

        isMaxSelectableReached() {
            return this.quantitySelected >= this.inventoryResults.maxSelectable;
        },

        cartButtonsDisabled() {
            return (item) => {
                const isSelected = this.lensesAdded.some((x) => x.lensId === item.lensId);
                return (
                    !item.quantity ||
                    (!isSelected &&
                        item.lensSourceId !== LENS_SOURCES.CONSIGNMENT && // We can assign consignment lenses without any limitations regardless of the STAAR lenses selected the maximum lenses per eye.
                        this.isMaxSelectableReached)
                );
            };
        },

        isInStatus() {
            return (item, statusValues) => {
                return statusValues.includes(item.status);
            };
        },

        cartButtonText() {
            return (item) => {
                switch (item.status) {
                    case LENS_STATUS.IN_CART:
                        return this.t('inventory_InCart');
                    case LENS_STATUS.RESERVED:
                        return this.t(
                            this.isConsignmentTabActive
                                ? 'inventory_Assigned'
                                : 'inventory_Reserved'
                        );
                    case LENS_STATUS.AVAILABLE:
                    case LENS_STATUS.ACTIVE:
                    default:
                        return this.t('inventory_AddToCart');
                }
            };
        },

        reserveOrCancelButtonText() {
            return (item) => {
                switch (item.status) {
                    case LENS_STATUS.IN_CART:
                    case LENS_STATUS.RESERVED:
                        return this.t('inventory_Cancel');
                    case LENS_STATUS.AVAILABLE:
                    case LENS_STATUS.ACTIVE:
                    default:
                        return this.isConsignmentTabActive
                            ? this.t('inventory_Assign')
                            : this.t('inventory_Reserve');
                }
            };
        },

        backButtonText() {
            return this.wasOperationPerfomed || this.isManufactureViewEnabled
                ? this.t('inventory_Cancel')
                : this.t('inventory_Back');
        },

        timer() {
            const minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
            const seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;

            return `${minutes}:${seconds}`;
        },

        isTimeAlmostOver() {
            return this.minutes <= MINUTES_TO_ALERT_TIME;
        },

        remainingManufactureLenses() {
            const {length: mtoLensesAdded = 0} = this.lensesAdded.filter((x) => x.isMto);
            return MAX_MANUFACTURE_LENS_IN_CART - mtoLensesAdded;
        },

        /**
         * Allow the lesser of either the remaining manufacturable lenses, or the remaining
         * selectable lenses.
         */
        manufactureQuantityOptions() {
            return Array.from(
                {
                    length: Math.min(
                        this.remainingManufactureLenses,
                        this.remainingSelectableLenses
                    ),
                },
                (_, i) => i + 1
            );
        },

        manufactureLensDisabled() {
            const mtoLens = this.lensesAdded.find((x) => x.isMto);
            return (
                !this.manufactureInfo ||
                this.isMaxSelectableReached ||
                get(this.inventoryResults, 'noLensReasons.noRegCountryRowExists', false) ||
                get(
                    this.inventoryResults,
                    'noLensReasons.sphereNotInRegCountryDiopterRange',
                    false
                ) ||
                !get(this.inventoryResults, 'canMto', true) ||
                get(mtoLens, 'quantity', 0) >= MAX_MANUFACTURE_LENS_IN_CART
            );
        },
    },
    methods: {
        formatLensNum,
        padNumber,
        ...mapActions({
            unlockLenses: 'preopdata/unlockLenses',
            primaryLens: 'patient/primaryLens',
        }),
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),
        swapArrayLocs(arr, index1, index2) {
            [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
        },
        updateTimer(event) {
            const timer = event.data;
            this.minutes = parseInt(timer / 60, 10);
            this.seconds = parseInt(timer % 60, 10);
            if (timer === 0) {
                this.onConfirmCancelation();
                this.showWarningModal({
                    title: this.t('information'),
                    text: this.t('invLookup_Dismissed'),
                    position: 'center',
                    bgVariant: 'info',
                    okVariant: 'secondary',
                    cancelTitle: '',
                });
            }
        },
        lensInventoryMapper(lensInventory) {
            lensInventory.forEach((lens) => {
                lens.lensId = uniqueId('LENS-');
                //find the lens in allLensInfo
                //and update the expected Ref & SEQ fields
                let cyl = lens.cylinder;
                let sphere = lens.sphere;
                let section = this.allLensInfo?.find((v) => v.cylinder == cyl);
                if (section) {
                    //with the section, find the index that matches the sphere value we have
                    let sphereIndex = section.sphere.findIndex((v) => v == sphere);
                    //use this index to build the ExpRef and ExpSeq
                    if (sphereIndex) {
                        lens.expectedSeq = section.expSeq[sphereIndex];
                    }
                }
            });
        },
        getQuantityOptions(item) {
            const serialsCount = get(item, 'serials.length', 0);
            const allowSelectableQuantity =
                serialsCount > this.remainingSelectableLenses
                    ? this.remainingSelectableLenses
                    : serialsCount;
            item.quantity = allowSelectableQuantity === 1 ? 1 : item.quantity;
            return Array.from({length: allowSelectableQuantity}, (_, i) => i + 1);
        },
        setQuantity(item, value) {
            item.quantity = value;
        },
        cleanQuantities(item) {
            [...this.localInventory, ...this.staarInventory]
                .filter(
                    (lens) =>
                        lens.lensId !== item.lensId &&
                        this.isInStatus(lens, [LENS_STATUS.AVAILABLE, LENS_STATUS.ACTIVE])
                )
                .forEach((lens) => {
                    lens.quantity = this.getQuantityOptions(lens).length == 1 ? 1 : 0;
                });
        },
        onConfirmCancelation() {
            this.lensesAdded = [];
            this.showPrompt = false;
            this.onBackOrCancel();
        },
        onBackOrCancel() {
            if (this.isManufactureViewEnabled) {
                this.isManufactureViewEnabled = false;
                return;
            }

            if (this.wasOperationPerfomed) {
                this.showPrompt = true;
                return;
            }

            this.unlockLenses({
                localLenses: this.inventoryResults.localLenses || [],
                consignmentLenses: this.inventoryResults.consignmentLenses || [],
                remoteLenses: this.inventoryResults.remoteLenses || [],
                isClosing: true,
            });
            this.$emit('update:showModal', false);
        },
        addToCart(item) {
            const isAdded = [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(item.status);
            if (!isAdded) {
                item.status = LENS_STATUS.IN_CART;
                item.addedToCart = new Date();
                this.lensesAdded.push(item);
            }
            this.cleanQuantities(item);
        },
        reserveOrCancel(item) {
            const isAdded = [LENS_STATUS.IN_CART, LENS_STATUS.RESERVED].includes(item.status);
            // Cancel
            if (isAdded) {
                if (item.status === LENS_STATUS.IN_CART) {
                    item.addedToCart = null;
                }
                // default lens quantity should be 1 if spherical with consignment source
                // otherwise must be zero to be able to select the quantity from quantity selector
                item.quantity = item.lensSourceId == LENS_SOURCES.CONSIGNMENT ? 1 : 0;
                item.status = LENS_STATUS.AVAILABLE;
                const indexAdded = this.lensesAdded.indexOf(item);
                this.lensesAdded.splice(indexAdded, 1);
                /* MTO lenses are added to inventory list when manufacture button get clicked
                 We need to remove them from inventory list when cancel button get clicked */
                if (item.isMto) {
                    const lenses = this.isUSBusinessUnit
                        ? this.inventoryResults.localLenses
                        : this.inventoryResults.remoteLenses;
                    const indexInventory = lenses.indexOf(item);
                    lenses.splice(indexInventory, 1);
                }
            } else {
                item.status = LENS_STATUS.RESERVED;
                this.lensesAdded.push(item);
            }
            this.cleanQuantities(item);
        },
        async selectedLengthChange(selectedLength) {
            const payload = {
                selectedLength,
                pendingAddToCart: this.lensesAdded.filter((i) => i.status === LENS_STATUS.IN_CART),
                pendingReserve: this.lensesAdded.filter((i) => i.status === LENS_STATUS.RESERVED),
                callback: this.selectTab,
            };
            await this.unlockLenses({
                localLenses: this.inventoryResults.localLenses || [],
                consignmentLenses: this.inventoryResults.consignmentLenses || [],
                remoteLenses: this.inventoryResults.remoteLenses || [],
                isClosing: false,
            });
            this.$emit('on-selected-length-change', payload);
            this.selectedLength = selectedLength;
        },
        selectTab() {
            this.isManufactureViewEnabled = false;
            const lensSourceIdWithHighestPriorityLens = get(
                this.inventoryResults,
                'lensSourceIdWithHighestPriorityLens',
                0
            );

            switch (lensSourceIdWithHighestPriorityLens) {
                case LENS_SOURCES.CONSIGNMENT:
                    this.tabIndex = LENS_TAB_INDEXES.CONSIGNMENT;
                    return;
                case LENS_SOURCES.STAARLOCAL:
                    this.tabIndex = this.isUSBusinessUnit
                        ? LENS_TAB_INDEXES.STAAR
                        : LENS_TAB_INDEXES.LOCAL;
                    return;
                case LENS_SOURCES.STAARREMOTE:
                    this.tabIndex = LENS_TAB_INDEXES.STAAR;
                    return;
                default:
                // fall through to standard behavior
            }

            if (this.shouldShowConsignmentInventory && this.consignmentInventory.length) {
                this.tabIndex = LENS_TAB_INDEXES.CONSIGNMENT;
            } else if (this.shouldShowLocalInventory && this.localInventory.length) {
                this.tabIndex = LENS_TAB_INDEXES.LOCAL;
            } else {
                this.tabIndex = LENS_TAB_INDEXES.STAAR;
            }
        },
        showManufactureView() {
            this.isManufactureViewEnabled = true;
            this.tabIndex = LENS_TAB_INDEXES.STAAR;
            this.manufactureQuantity = this.manufactureQuantityOptions.length;
        },
        manufactureLens() {
            if (!this.manufactureQuantity) return;
            let mtoLens = this.lensesAdded.find(
                (x) => x.isMto && x.model === this.manufactureInfo.model
            );
            const lenses = this.isUSBusinessUnit
                ? this.inventoryResults.localLenses
                : this.inventoryResults.remoteLenses;
            if (!mtoLens) {
                mtoLens = {...DefaultManufactureInventory(), ...this.manufactureInfo};
                this.addToCart(mtoLens);
                lenses.unshift(mtoLens);
            }
            mtoLens.quantity += this.manufactureQuantity;
            // We need to ensure sync between the lenses in lensesAdded array and the lenses comming from server.
            // The lenses comming from server (inventoryResults) loose reference to lensesAdded array when inventory panel change.
            const quantity = mtoLens.quantity;
            mtoLens = lenses.find((x) => x.isMto && x.model === this.manufactureInfo.model);
            if (mtoLens) {
                mtoLens.quantity = quantity;
            }
            this.onBackOrCancel();
        },
        async done() {
            const payload = {
                calculationId: this.inventoryResults.calculationId,
                addToCart: this.lensesAdded.filter((i) => i.status === LENS_STATUS.IN_CART),
                reserve: this.lensesAdded.filter((i) => i.status === LENS_STATUS.RESERVED),
            };

            try {
                const {success, error, results} = await this.blockingRequest(
                    'cart/submitLenses',
                    payload
                );
                if (get(results, 'errored', []).length) {
                    const messages = results.errored.map((item) =>
                        this.$createElement('p', {}, [`${item.lens.model}: ${this.t(item.reason)}`])
                    );
                    this.$bvToast.toast(messages, {
                        title: this.t('error'),
                        variant: 'danger',
                        solid: true,
                        autoHideDelay: 10000,
                    });
                } else if (success) {
                    await this.blockingRequest('cart/fetchItemsCount');
                    this.onConfirmCancelation();
                    this.$emit('on-locked');
                } else {
                    throw error;
                }
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCodeDescription || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
        printReport() {
            const routeData = this.$router.resolve({
                name: 'InventoryLookupReport',
                query: {
                    ['account-id']: this.activeCustomerOcosNumberOrId,
                },
            });

            window.open(routeData.href, '_blank');
        },
    },
    mounted() {
        this.worker.onmessage = this.updateTimer;
    },
};
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '../assets/css/variables';

@include media-breakpoint-only(xs) {
    .w-100pm360 {
        max-width: 360px;
        width: 100%;
    }
}

@include media-breakpoint-up(sm) {
    .w-360px {
        width: 360px;
    }
}

@media (min-width: 768px) {
    ::v-deep .modal-xl {
        width: 90%;
        max-width: 75vw;
    }
}

::v-deep .inventory-lookup-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

::v-deep .inventory-lookup-header {
    align-items: center;
}

::v-deep .inventory-table td {
    padding: 0.3rem 1rem 0.3rem 1rem;
    vertical-align: middle;
    white-space: nowrap;
}

::v-deep .modal-body {
    height: 580px; //calc(100vh - 200px);
    overflow-y: auto;
}

::v-deep .nav-pills .nav-link.active {
    background-color: #1e555c;
    &:focus {
        color: white !important; /* bootstrap override without it */
    }
}

.text-title {
    font-weight: bold;
    font-size: 1.14rem;
}

.manufacture-overlay {
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .info {
        font-size: 1.4rem;
        font-weight: bolder;
    }
}

.min-w-92px {
    min-width: 92px;
}

.min-w-72px {
    min-width: 72px;
}

#printReport {
    color: white;
    background-color: #616469;
}

.min-w-82px {
    min-width: 82px;
}
.w-135px {
    width: 135px;
}
.w-40px {
    width: 40px;
}
.note-not-exact-match {
    top: 100px;
    position: relative;
}
.centered-select {
    background-position-x: 70px;
}
</style>
